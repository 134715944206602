import React from 'react';
import styled from 'styled-components';

import Container from '../Container';
import LocationFinder from '../LocationFinder/LocationFinder';
import Title from '../Title';

type Props = {};

const Wrapper = styled.div`
	padding-top: ${({ theme }) => theme.spacing.sectionPaddingSm};
	padding-bottom: ${({ theme }) => theme.spacing.sectionPaddingSm};
	background: ${({ theme }) => theme.colors.blackish};
	${Container} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const LocationFinderFooter: React.FC<Props> = () => {
	return (
		<Wrapper>
			<Container>
				<Title color="inverted" as="h3">
					Find your local TownSq
				</Title>
				<LocationFinder />
			</Container>
		</Wrapper>
	);
};

export default LocationFinderFooter;
