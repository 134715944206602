export const moveUpFadeIn = {
	variants: {
		show: { opacity: 1, y: 0 },
		hide: { opacity: 0, y: 15 },
	},
	initial: 'hide',
	animate: 'show',
	transition: {
		duration: 0.5,
	},
};
