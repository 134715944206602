import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import GoogleAnalytics from 'react-ga';
import { navigate } from 'gatsby';
import { motion, AnimationProps } from 'framer-motion';

import Btn from '../Button';
import useNearestLocation from '../../utils/hooks/useNearestLocation';
import RequestSpaceModal from '../RequestSpaceModal';
import useSpaces from '../../utils/hooks/useSpaces';

const btnWidth = '113px';

const Button = styled(Btn)`
	width: ${btnWidth};
`;

type Props = AnimationProps & {
	className?: string;
};

const ErrorMsg = styled.small`
	position: absolute;
	top: 125%;
	left: 0;
	width: 100%;
	padding: 8px 6px 6px;
	text-align: center;
	font-size: 0.9rem;
	background: #fff;
	color: ${({ theme }) => theme.colors.primary};
`;

const Form = styled(motion.form)<{ showLabel: boolean }>`
	position: relative;
	display: flex;
	align-items: stretch;
	width: 100%;
	max-width: 380px;

	svg {
		position: absolute;
		top: 45%;
		left: 0;
		transform: translateY(-50%);
		font-size: 18px;
		color: ${({ theme }) => theme.colors.primary};
	}

	label {
		position: absolute;
		top: 50%;
		left: 30px;
		transform: translateY(-50%);
		font-size: ${({ theme }) => theme.fontSize.bodyLg};
		color: ${({ theme }) => theme.colors.white};
		font-weight: ${({ theme }) => theme.fontWeight.normal};
		transition: opacity 0.2s;
		opacity: ${({ showLabel }) => (showLabel ? 1 : 0)};
	}

	input {
		flex: 1;
		padding-left: 30px;
		margin-right: 8px;
		border: 0;
		border-bottom: 2px solid rgba(255, 255, 255, 0.7);
		background: transparent;
		transition: border-color 0.3s;
		font-size: ${({ theme }) => theme.fontSize.bodyLg};
		color: ${({ theme }) => theme.colors.white};
		font-weight: ${({ theme }) => theme.fontWeight.normal};

		&::placeholder {
			color: #fff;
		}

		:focus {
			outline: 0;
			border-color: ${({ theme }) => theme.colors.primaryTrans};
		}
	}

	@media (max-width: 429px) {
		svg {
			display: none;
		}

		input {
			padding: 0;
		}

		label {
			left: 0;
		}
	}
`;

const postcodeRegex = RegExp(
	'([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))s?[0-9][A-Za-z]{2})'
);

const ERRORS = {
	INVALID: 'Please enter a valid UK location',
};

const isPostcodeValid = (input: string) => {
	return postcodeRegex.test(input.replace(/ /g, ''));
};

// 20 miles in metres
const TWENTY_MILES = 32186.9;

const LocationFinder: React.FC<Props> = ({
	className,
	children,
	...animationProps
}) => {
	const [error, setError] = useState('');
	const [input, setInput] = useState('');
	const [focused, setFocused] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	type Location = {
		slug: string;
		title: string;
	};
	const [nearestLocation, setNearestLocation] = useState<Location>();
	const { finding, findNearestLocation } = useNearestLocation();
	const { findOne } = useSpaces();

	const $input = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!error) return;
		const isValid = isPostcodeValid(input);
		if (isValid) {
			setError('');
		}
	}, [input]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			// const isValid = isPostcodeValid(input);

			if (!input) {
				throw new Error(ERRORS.INVALID);
			}

			setSubmitting(true);

			const nearestSpace = await findNearestLocation(input);
			const isTooFar = (nearestSpace?.distanceInMetres || 0) > TWENTY_MILES;

			if (!nearestSpace?.spaceSlug) {
				navigate('/spaces');
			} else if (isTooFar) {
				const space = findOne({ slug: nearestSpace.spaceSlug });

				if (process.env.NODE_ENV !== 'development') {
					GoogleAnalytics.event({
						category: 'Location finder',
						action: 'Search',
						label: input,
					});
				}

				if (space) {
					setNearestLocation({
						slug: space?.node.slug,
						title: space?.node.title,
					});
				} else {
					navigate('/spaces');
				}
			} else {
				navigate(`/spaces/${nearestSpace.spaceSlug}`);
			}
		} catch (e) {
			const knownError = Object.values(ERRORS).find(
				(item) => item === e.message
			);
			setError(knownError ? e.message : 'Something went wrong');
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Form
			onSubmit={handleSubmit}
			showLabel={!focused && !input}
			{...animationProps}
		>
			<FontAwesomeIcon icon={faSearch} />
			<label className="sr-only" htmlFor="location">
				Enter your postcode to find your local Town Sq
			</label>
			<input
				type="text"
				id={'location'}
				ref={$input}
				value={input}
				placeholder="Find my local Town Sq"
				onChange={(e) => setInput(e.target.value)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
			<Button disabled={submitting}>Submit</Button>
			{error && <ErrorMsg>{error}</ErrorMsg>}

			<RequestSpaceModal
				open={!!nearestLocation}
				onClose={() => setNearestLocation(undefined)}
				space={nearestLocation}
			/>
		</Form>
	);
};

export default LocationFinder;
