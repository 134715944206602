import React, { Fragment } from 'react';
import { Link, graphql, PageProps } from 'gatsby';

import { Context, MetaData } from '../components';
import LocationFinderHeroBanner from '../components/LocationFinderHeroBanner';
import Testimonial from '../components/Testimonial';
import Features from '../components/Features';
import CTA from '../components/CTA';
import LatestNews from '../data/LatestNews';
import SpaceSquares from '../components/SpaceSquares';
import LocationFinderFooter from '../components/LocationFinderFooter';

type Props = PageProps & {
	data: GatsbyTypes.HomePageQuery;
};

const Home: React.FC<Props> = ({ data }) => {
	const { wordpressPage: page } = data;

	return (
		<Context.Consumer>
			{({ setModal }) => {
				return (
					<Fragment>
						<MetaData
							title={page?.acf?.meta_title || page?.title}
							description={page?.acf?.meta_description}
						/>

						<LocationFinderHeroBanner
							prependSlides={[
								{
									image: {
										image: page?.acf?.banner_first_slide_image,
										focus: 'center',
									},
									text: page?.acf?.banner_first_slide_text,
								},
							]}
							templateText={page?.acf?.banner_text || ''}
						/>

						<Features features={page?.acf?.features?.features} />

						<Testimonial
							memberId={page?.acf?.testimonial?.testimonial_community_member}
							image={page?.acf?.testimonial?.testimonial_image?.source_url}
							quote={page?.acf?.testimonial?.testimonial_testimonial || ''}
							author={{
								name: page?.acf?.testimonial?.testimonial_name,
								company: page?.acf?.testimonial?.testimonial_company,
							}}
						/>

						<CTA
							hide={page?.acf?.cta?.call_hide}
							title={page?.acf?.cta?.call_title || ''}
							text={page?.acf?.cta?.call_text || ''}
							link={page?.acf?.cta?.call_link || ''}
							linkText={page?.acf?.cta?.call_link_text || ''}
						/>

						<LatestNews limit={3} />

						<SpaceSquares
							padTop={false}
							title={page?.acf?.space_squares?.space_squares_title}
							ids={page?.acf?.space_squares?.space_squares_spaces?.map(
								(item) => item?.wordpress_id ?? 0
							)}
						/>

						<LocationFinderFooter />
					</Fragment>
				);
			}}
		</Context.Consumer>
	);
};

export const pageQuery = graphql`
	query HomePage {
		wordpressPage(slug: { eq: "home" }) {
			title
			acf {
				meta_title
				meta_description
				banner_first_slide_image {
					source_url
					alt_text
				}
				banner_first_slide_text
				banner_text
				testimonial {
					testimonial_community_member
					testimonial_testimonial
					testimonial_name
					testimonial_company
					testimonial_image {
						source_url
						alt_text
					}
				}
				features {
					features {
						icon
						title
						link
					}
				}
				cta {
					call_hide
					call_title
					call_text
					call_link
					call_link_text
				}
				space_squares {
					space_squares_title
					space_squares_spaces {
						wordpress_id
					}
				}
			}
		}
	}
`;

export default Home;
