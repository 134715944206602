import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import motion from 'framer-motion';

import HeroBanner from '../HeroBanner';
import LocationFinder from '../LocationFinder/LocationFinder';
import BaseTitle from '../Title';
import { moveUpFadeIn } from '../../styles/animation';

type Props = {
	prependSlides: Array<{
		text: string;
		image: {
			alt_text: string;
			source_url: string;
		};
	}>;
	templateText: string;
};

const animations = {
	title: moveUpFadeIn,
	locationFinder: {
		...moveUpFadeIn,
		transition: {
			...moveUpFadeIn.transition,
			delay: 0.3,
		},
	},
};

const Title = styled(BaseTitle).attrs((props) => ({
	...props,
	letters: 'condensed',
	...animations.title,
}))``;

const Banner = styled(HeroBanner)`
	${Title} {
		max-width: 460px;
		margin-bottom: ${({ theme }) => theme.spacing[8]};
		text-align: center;
		line-height: 1.6;
	}
`;

const buildDynamicText = (text: string, injectedText: string) => {
	return text.replace('{SPACE}', injectedText);
};

const LocationFinderHeroBanner: React.FC<Props> = ({
	prependSlides,
	templateText,
}) => {
	const { spaces } = useStaticQuery<GatsbyTypes.LocaionFinderQuery>(graphql`
		query LocaionFinder {
			spaces: allWordpressWpSpace {
				edges {
					node {
						title
						wordpress_id
						slug
						acf {
							header {
								header_images {
									image {
										source_url
										alt_text
									}
									focus
								}
							}
						}
					}
				}
			}
		}
	`);

	const [text, setText] = useState<string>(
		prependSlides?.length
			? prependSlides[0].text
			: buildDynamicText(templateText, spaces.edges[0].node.title || '')
	);

	const handleSlideChange = (index: number) => {
		let text = prependSlides?.[index]?.text;
		const isSpace = index >= prependSlides.length;

		if (isSpace) {
			const trueIndex = index - prependSlides.length;
			text = buildDynamicText(
				templateText,
				spaces.edges?.[trueIndex]?.node.title || ''
			);
		}

		setText(text);
	};

	const images = [
		...prependSlides.map((item) => item.image),
		...spaces.edges.map(({ node }) => ({
			image: {
				...node?.acf?.header?.header_images?.[0]?.image,
				source_url:
					node?.acf?.header?.header_images?.[0]?.image?.source_url || '',
			},
			focus: node?.acf?.header?.header_images?.[0]?.focus || 'center',
		})),
	];

	return (
		<Banner onSlideChange={handleSlideChange} images={images}>
			<Title color="inverted" weight="light">
				{text}
			</Title>
			<LocationFinder {...animations.locationFinder} />
		</Banner>
	);
};

export default LocationFinderHeroBanner;
