import React, { useState } from 'react';

import isUKPostcode from '../../validation/isUKPostcode';

type NearestLocationRepsonse = {
	spaceSlug: string;
	distanceInMetres: number;
};

const useNearestLocation = () => {
	const [finding, setFinding] = useState(false);

	/**
	 * Find the nearest TownSq space
	 * @param {string} input Postcode from the user
	 */
	const findNearestLocation: (
		input: string
	) => Promise<NearestLocationRepsonse | null> = async (input: string) => {
		setFinding(true);

		try {
			// if (!isUKPostcode(input)) {
			// 	throw new Error("Please enter a valid postcode");
			// }

			const response: Promise<NearestLocationRepsonse> = await fetch(
				`/.netlify/functions/findNearestSpace?originLocation=${input}`
			).then((res) => res.json());

			return response;
		} catch (e) {
			console.log('findNearestLocation error', e);
			return null;
		} finally {
			setFinding(false);
		}
	};

	return {
		finding,
		findNearestLocation,
	} as const;
};

export default useNearestLocation;
