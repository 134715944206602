import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

import Modal, { ModalProps } from '../Modal';
import RequestSpaceForm from '../RequestSpaceForm';
import UserContent from '../UserContent';

type RequestSpaceModalProps = ModalProps & {
	space?: {
		slug: string;
		title: string;
	};
};

const FALLBACK_TITLE = 'Request a TownSq space near you';
const NO_SPACE_CONTENT = "We couldn't find a TownSq space near you.";
const FALLBACK_CONTENT = 'Your nearest TownSq space is {{SPACE}}.';

const RequestSpaceModal = ({ space, ...props }: RequestSpaceModalProps) => {
	const [sent, setSent] = useState(false);

	const { options } =
		useStaticQuery<GatsbyTypes.RequestSpaceModalQuery>(graphql`
			query RequestSpaceModal {
				options: wordpressAcfOptions {
					options {
						request_a_space_title
						request_a_space_content
					}
				}
			}
		`);

	const title = options?.options?.request_a_space_title || FALLBACK_TITLE;
	const content = options?.options?.request_a_space_content || FALLBACK_CONTENT;
	const finalContent = space
		? content.replace(
				/{{SPACE}}/g,
				`<a href="/spaces/${space.slug}">${space.title}</a>`
		  )
		: NO_SPACE_CONTENT;

	return (
		<Modal
			{...props}
			title={title}
			content={
				<>
					{!sent && <Content content={finalContent} />}
					<RequestSpaceForm onDone={() => setSent(true)} />
				</>
			}
		/>
	);
};

const Content = styled(UserContent)`
	margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

export default RequestSpaceModal;
